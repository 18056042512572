import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { TokenService } from '../../shared/services/token.service';
import { AppConfig } from '../../shared/constants/global-constants';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  callagain = true;
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  filename;

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private authService: AuthService
  ) { }
  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   const token = this.tokenService.getToken();
  //   const refreshToken = this.tokenService.getRefreshToken();

  //   if (req.headers.has('Skip-Auth')) {
  //     req = req.clone({
  //       headers: req.headers.delete('Skip-Auth'),
  //     });
  //     return next.handle(req);
  //   }

  //   if (token) {
  //     req = req.clone({
  //       setHeaders: {
  //         Authorization: 'Bearer ' + token,
  //       },
  //     });
  //   }

  //   if (!req.headers.has('Content-Type')) {
  //     req = req.clone({
  //       setHeaders: {
  //         'content-type': 'application/json',
  //       },
  //     });
  //   }

  //   req = req.clone({
  //     headers: req.headers.set('Accept', 'application/json'),
  //   });




  //   return next.handle(this.callAPI(token, req)).pipe(
  //     catchError((error: any) => {
  //       if (error.status === 401 &&
  //         (error?.error?.message === "Access token is invalid" || error?.error?.message === "Access token has been revoked")) {
  //         return this.handleError(req, next);
  //       }
  //       return throwError(error);
  //     })
  //   ) as Observable<HttpEvent<any>>;;
  // }
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.tokenService.getToken();
    const refreshToken = this.tokenService.getRefreshToken();

    if (request.headers.has('Skip-Auth')) {
      request = request.clone({
        headers: request.headers.delete('Skip-Auth'),
      });
      return next.handle(request);
    }

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json'

        },
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')

    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //   console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: any) => {
        if (error.status === 401 ||
          (error?.error?.message === 'Access token is invalid' || error?.error?.message === 'Access token has been revoked')) {
          return this.handleError(request, next);
        }
        return throwError(error);
      })
    ) as Observable<HttpEvent<any>>;
    // return next.handle(request).pipe(
    //   map((event: HttpEvent<any>) => {
    //     if (event instanceof HttpResponse) {
    //       console.log('event--->>>', event);
    //     }
    //     return event;
    //   }),
    //   catchError((error: HttpErrorResponse) => {

    //     if ((error.status === 401||error.status===403) &&this.callagain) {
    //       this.callagain=false;
    //          console.log('REFRESH TOKEN');
    //         this.authService
    //         .login({ username: 'arraza@ap.org', password: 'admin' })
    //         .subscribe((_) => {

    //         });


    //     if (error.error.message == 'The refresh token is invalid.') {
    //         this.authService
    //           .login({ username: 'arraza@ap.org', password: 'admin' })
    //           .subscribe((_) => location.reload());
    //       }
    //     }
    //     return throwError(error);
    //   })
    // );
  }
  handleError(request, next): any {
    if (request.url.includes('oauth/token')) {
      this.isRefreshingToken = false;
      this.router.navigate(['/auth/login']);

    }
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);

      return this.authService.refreshToken(this.getRefreshedToken()).pipe(switchMap(res => {
        if (res) {
          this.tokenSubject.next(res.access_token);
          this.tokenService.saveToken(res.access_token);
          this.tokenService.saveRefreshToken(res.refresh_token);

          return next.handle(this.callAPI(res.access_token, request));
        }

        return null;
      }),
        catchError(err => {
          if (err?.error?.message === 'The refresh token is invalid.') {

            return null;
          }
          return throwError(err);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }));
    } else {

      return this.tokenSubject
        .pipe(filter(token => token != null),
          take(1),
          switchMap(token => {
            this.isRefreshingToken = false;
            return next.handle(this.callAPI(token, request));
          }));
    }
  }
  getRefreshedToken(): any {
    const rToken = this.tokenService.getRefreshToken();
    const formData = {
      grant_type: 'refresh_token',
      client_id: AppConfig.CLIENT_ID,
      refresh_token: rToken
    };

    return formData;
  }
  callAPI(token, req): any {
    let request;

    if (token) {

      request = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      });
    }
    return request;
  }

}

