import { Injectable } from '@angular/core';
import * as toastr from 'toastr';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  defaultOptions = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: 'toast-top-full-width',
    preventDuplicates: false,
    onclick: null,
    showDuration: '0',
    hideDuration: '0',
    timeOut: '3500',
    extendedTimeOut: '6000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
  };

  constructor() {
    toastr.options = this.defaultOptions;
  }
  showSuccess(message: string, showClose= false): void {
    if (showClose === true) {
      const tempOptions = JSON.parse(JSON.stringify(this.defaultOptions));
      tempOptions.closeButton = true;
      tempOptions.timeOut = '10000';
      toastr.options = tempOptions;
    }else{
      toastr.options = this.defaultOptions;
    }
    toastr.success(message);
  }
  showInfo(message: string, showClose= false, positionClass?): void {
    if (showClose === true) {
      const tempOptions = JSON.parse(JSON.stringify(this.defaultOptions));
      tempOptions.closeButton = true;
      tempOptions.timeOut = '10000';
      tempOptions.positionClass =  positionClass;
      toastr.options = tempOptions;
    }else{
      toastr.options = this.defaultOptions;
    }
    toastr.info(message);
  }
  showError(message: string, showClose= false): void {
    if (showClose === true) {
      const tempOptions = JSON.parse(JSON.stringify(this.defaultOptions));
      tempOptions.closeButton = true;
      tempOptions.timeOut = '10000';
      toastr.options = tempOptions;
    }else{
      toastr.options = this.defaultOptions;
    }
    toastr.error(message);
  }
  showWarning(message: string, showClose= false): void {
    if (showClose === true) {
      const tempOptions = JSON.parse(JSON.stringify(this.defaultOptions));
      tempOptions.closeButton = true;
      tempOptions.timeOut = '10000';
      toastr.options = tempOptions;
    }else{
      toastr.options = this.defaultOptions;
    }
    toastr.warning(message);
  }
  clearMessages(): void {
    toastr.clear();
  }
}
