import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MaterialModule} from '../shared/material/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelpDialogComponent } from './components/help-dialog/help-dialog.component';
import { SearchComponent } from './components/search/search.component';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { MatProgressBar } from '@angular/material/progress-bar';
screenTop;

@NgModule({
  declarations: [HelpDialogComponent, SearchComponent, HighlightSearchPipe, FormatDatePipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    MaterialModule
  ],
  exports: [MaterialModule, NgxSpinnerModule, HelpDialogComponent, SearchComponent, FormatDatePipe],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {} // Add any data you wish to test if it is passed/used correctly
    }
  ],
})
export class SharedModule { }
