<div class="banner" *ngIf="bannerDetails">
    <span class="alert-img">

         <span>
             <img src="../../../assets/images/alert.png" alt="">
             {{bannerDetails[0].title}}
              <a class="link" target="_blank" *ngIf="bannerDetails[0].field_link_1" href="{{bannerDetails[0].field_link_1}}">View Details</a>
            </span>
    </span>


</div>
