import { Injectable, NgZone } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../core/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router, private zone: NgZone) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // const token = localStorage.getItem('access_token');
    // if (!token) {
    //   this.zone.run(() => this.router.navigate(['/auth/login']));
    //   localStorage.clear();
    //   return false;
    // }
    // else {
    //   if (state.url === '/') {
    //     this.zone.run(() => this.router.navigate(['/features/all-media']));
    //   }
    // }

    if (this.auth.isAuthenticated()) {
      if (state != null && state.url === '/') {
        this.zone.run(() => this.router.navigate(['/features/all-media']));
      }
    }
    else {
      this.zone.run(() => this.router.navigate(['/auth/login']));
      localStorage.clear();
      return false;
    }
    return true;

  }

}
