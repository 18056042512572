import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SuggestionData, Suggestions } from '../../models/search-suggestions.model';
import { SearchFilterService } from '../../services/search-filter.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  currentType: any;

  constructor(
    private searchService: SearchFilterService,
    private router: Router
  ) {}
  searchField = new FormControl();
  suggestions?: SuggestionData[];
  focusSearch = false;
  focusList = false;
  issearch = false;
  show = true;
  ngOnInit(): void {
  this.setSearchField(this.searchService.searched);
  this.searchField.valueChanges.subscribe((value) => {
      const arr = this.router.url.split('/');
      this.currentType = arr[2];
      if (this.currentType === 'all-media') {
        this.currentType = 'all_media';
      }
      if (!value) {
        this.suggestions = [];
        return;
      }
      this.searchService
        .getSuggestions(value, this.currentType)
        .subscribe((suggestions: Suggestions) => {
          this.suggestions = suggestions.data;
        });
    });
  }
  onSubmit(): void {
    const keyword = this.searchField.value;
    // console.log(keyword);
    this.searchService.setSearchKeyword(keyword);
    this.searchService.emitSearch(keyword);
    this.searchService.setSearchCheck(true);
  }
  clickSugg(sugg: any): void {
    this.searchField.setValue(sugg.title[0]);
    this.router.navigate(['features/' + sugg.type[0] + '/' + sugg.nid[0]]);
  }
  setSearchField(val): void
  {
    this.searchField.setValue(val);
  }
  search(event): void
  {
  }
  clear(): void
  {
    this.searchField.setValue('');
    this.searchService.setSearchKeyword('');
    if (this.searchService.issearch)
    {


      this.searchService.emitSearch('');
      this.issearch = false;
    }

  }
}
