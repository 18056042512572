<div
  class="side-bar d-flex flex-column flex-shrink-0 bg-white vh-100 fixed-top"
  style="width: 280px"
>
  <a routerLink="" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto">
    <img
      src="../../../assets/brand.svg"
      width="70"
      height="70"
      class="d-inline-block align-top"
      alt="brand-logo"
    />
  </a>
  <hr />
  <ul class="nav flex-column">
    <li class="nav-item" *ngFor="let topic of hotTopics">
      <a
        [routerLink]="['/filters', topic.id, 'all-media']"
        class="nav-link text-dark"
      >
        <strong> {{ topic.title }} </strong></a
      >
    </li>
    <li></li>
  </ul>

  <hr />

  <a
    *ngIf="showFilter"
    class="filter"
    (click)="collapse.toggle()"
    [attr.aria-expanded]="!filterCollapse"
  >
    <span>Filter and Sort</span>
    <div class="filter-caret">
      <img src="../../../../assets/images/caret-down.png" alt="" />
    </div>
  </a>
  <div >
    <div class="clear-filter">
      <a (click)="resetForm()">Clear all</a>
    </div>

    <form [formGroup]="filterForm">
      <h6>DATE</h6>
      <div class="radio-wrapper">
        <input
          type="radio"
          id="any"
          value="any"
          formControlName="duration_filter"
        />
        <label class="radio-label" for="any">Anytime</label>
      </div>

      <div class="radio-wrapper">
        <input
          type="radio"
          id="24H"
          value="24_h"
          formControlName="duration_filter"
        />
        <label class="radio-label" for="24H">Last 24 hours</label>
      </div>

      <div class="radio-wrapper">
        <input
          type="radio"
          id="14D"
          value="14_d"
          formControlName="duration_filter"
        />
        <label class="radio-label" for="14D">Last 14 days</label>
      </div>

      <div class="radio-wrapper">
        <input
          type="radio"
          id="30D"
          value="30_d"
          formControlName="duration_filter"
        />
        <label class="radio-label" for="30D">Last 30 days</label>
      </div>

      <h6>DATE RANGE</h6>
 <div class="date-range">

        <input class="mb-2"
          formControlName="startDate_filter"
          type="text"
          placeholder="Start Date"
          onfocus="(this.type='date')"
          onblur="(this.type='text')"
        />

        <input
          formControlName="endDate_filter"
          type="text"
          placeholder="End Date"
          onfocus="(this.type='date')"
          onblur="(this.type='text')"
        />
 </div>

      <h6>CATEGORY</h6>
      <div ngbRadioGroup>
        <div class="checkbox-toolbar">
          <div *ngFor="let item of categories">
            <input
              formControlName="category_filter"
              type="radio"
              [id]="'category_' + item.value"
              name="category_filter"
              [value]="item.value"
              checked
            />
            <label [for]="'category_' + item.value">{{ item.name }}</label>
          </div>
        </div>
      </div>
    </form>
  </div>

  <a
    *ngIf="showMedia"
    (click)="mediaCollapse.toggle()"
    [attr.aria-expanded]="!mediaFilterCollapse"
    class="filter"
  >
    <span>Media Types</span>
    <div class="filter-caret">
      <img src="../../../../assets/images/caret-down.png" alt="" />
    </div>
  </a>
  <div >
    <div class="radio-wrapper">
      <input
        type="radio"
        [value]="'all-media'"
        id="all-media"
        [formControl]="mediaTypeRadio"
      />
      <label class="radio-label" for="all-media">All Media</label>
    </div>
    <div class="radio-wrapper">
      <input
        type="radio"
        id="text"
        [value]="'text'"
        [formControl]="mediaTypeRadio"
      />
      <label class="radio-label" for="text">Text</label>
    </div>
    <div class="radio-wrapper">
      <input
        type="radio"
        id="photo"
        [value]="'photo'"
        [formControl]="mediaTypeRadio"
      />
      <label class="radio-label" for="photo">Photo</label>
    </div>
    <div class="radio-wrapper">
      <input
        type="radio"
        id="video"
        [value]="'video'"
        [formControl]="mediaTypeRadio"
      />
      <label class="radio-label" for="video">Video</label>
    </div>
    <div class="radio-wrapper">
      <input
        type="radio"
        id="audio"
        [value]="'audio'"
        [formControl]="mediaTypeRadio"
      />
      <label class="radio-label" for="audio">Audio</label>
    </div>
  </div>
</div>

