import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { SiteStatusComponent } from './static/site-status/site-status.component';

const routes: Routes = [
  {
    path: 'status-page',
    component: SiteStatusComponent,
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
     // Should be replaced with actual auth guard
    children: [
      {
        path: 'features',
        loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule)
        ,
    canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)

  },
  { path: '**', redirectTo: '/features/all-media', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
