import { Component, OnInit } from '@angular/core';
import { RSA_NO_PADDING } from 'constants';
import { first } from 'rxjs/operators';
import { HelperService } from '../../shared/services/helper.service';
import { NotificationService } from '../../shared/services/notification.service';

@Component({
  selector: 'app-site-status',
  templateUrl: './site-status.component.html',
  styleUrls: ['./site-status.component.scss']
})
export class SiteStatusComponent implements OnInit {
  pageTitle = '';
  pageBody = '';
  showLoader = false;
  constructor(private helperService: HelperService, private notifyService: NotificationService) { }

  ngOnInit(): void {
    this.getSiteContent();
  }

  getSiteContent(): void {
    this.showLoader = true;
    this.helperService.getSiteStatus().pipe(first()).subscribe(resp => {
      if (resp) {
        this.pageBody = resp[0]?.body[0]?.processed;
        this.pageTitle = resp[0]?.title[0]?.value  
        this.showLoader = false;
      }
    }, err => {
      this.notifyService.showError(err.error.message);
      this.showLoader = false;
    });
  }

}
