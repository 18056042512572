import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HotTopic } from '../../shared/models/hot-hopic.model';
import { SearchFilterService } from '../../shared/services/search-filter.service';
@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  filterCollapse = true;
  mediaFilterCollapse = true;
  showMedia = false;
  showFilter = true;
  filterForm = new FormGroup({
    duration_filter: new FormControl(''),
    startDate_filter: new FormControl(''),
    endDate_filter: new FormControl(''),
    category_filter: new FormControl(''),
  });
  mediaTypeRadio = new FormControl('');
  hotTopics?: HotTopic[];
  categories?: { name: string; value: number }[];
  mediaType?: string;

  constructor(
    private filterService: SearchFilterService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.filterService.getHotTopics().subscribe((topics: HotTopic[]) => {
      this.hotTopics = topics;
    });
    this.filterForm.valueChanges.subscribe((value) => {
      this.filterService.emitFilter(value);
      //  console.log('FILTER', value);
    });
    this.filterService.filterDataListner.subscribe((value) => {
      //   console.log('Data To FIlter', value);
      this.showMedia =
        value.showMediaType !== undefined ? value.showMediaType : this.showMedia;
      if (!this.showMedia) {
        this.mediaFilterCollapse = true;
      }
      if (this.showFilter) {
        this.filterCollapse = true;
      }
      this.showFilter =
        value.showFilter !== undefined ? value.showFilter : this.showFilter;
      this.categories =
        value.categories !== undefined
          ? (value.categories as { name: string; value: number }[])
          : this.categories;
      if (value.mediaType !== undefined) {
        this.mediaTypeRadio.setValue(value.mediaType);
        this.mediaType = value.mediaType;
      }
    });
    this.mediaTypeRadio.valueChanges.subscribe((val) => {
      this.router.navigateByUrl(this.router!.url.replace(this.mediaType!, val));
    });
  }
  resetForm(): void {
    this.filterForm.reset();
  }

}
