<div class="centered-div">
    <div class="login-div">
        <div>
            <div class="login-logo">
                <a routerLink="" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto login-logo">
                    <img src="../../../assets/brand.svg" width="70" height="70" class="d-inline-block align-top"
                        alt="brand-logo" />
                </a>
            </div>



            <div class="row page-content">
                
                <mat-progress-bar class="sitestatus-loader" mode="indeterminate" *ngIf="showLoader"></mat-progress-bar>


                <h3>{{pageTitle}}</h3>

                <div [innerHTML]="pageBody"></div>
            </div>
        </div>

        <br> <br>
        <div class="">
            <div class="support-details">
                <div>
                    <p>Are you having trouble with your account?</p>
                </div>
                <div class="support-contact">
                    <p>Contact Us:</p>
                    <span>
                        <img src="../../../assets/images/email.svg" alt="" />
                        <span class="support-email">apcustomersupport@ap.org</span>
                    </span>
                    <span class="support-numbers">
                        <span>
                            <img src="../../../assets/images/phone.svg" alt="" />
                            844-777-2006 (US)
                        </span>
                        <span> 44-808-134-6420 (UK) </span>
                        <span> 44-330-054-3330 (International) </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>