<ng-container mat-dialog-content>
  <div appTheme class="faq">

    <h1 mat-dialog-title>
      <span>
        Frequently Asked Questions (FAQs)
      </span>
      <span>
        <b><a href="https://ripcordterraformstorage.blob.core.windows.net/how-to-docs/how-to-documents/customer.pdf" target="_blank">Download User Manual</a></b>
      </span>
      <span class="close-icon">
        <img src="../../../../assets/images/close.png" alt="" (click)="dialogRef.close(false)">
      </span>
    </h1>

    <div *ngIf="showLoader">
      <mat-progress-bar mode="indeterminate" class="faq-loader"></mat-progress-bar>
    </div>

    <div mat-dialog-content *ngFor="let faq of FaqsList">
      <!-- <h4>{{data[0].title}}</h4> -->
      <!-- <h4>{{faq.title}}</h4> -->
      <div class="qa-list">

        <!-- <p>{{data[0].body}}</p> -->
        <div [innerHTML]="faq.body" ></div>
      </div>

    </div>





  </div>
</ng-container>