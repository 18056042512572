import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {AppConfig} from '../../shared/constants/global-constants';
import {BaseService} from '../../shared/services/base.service';
import {TokenService} from '../../shared/services/token.service';

const OAUTH_CLIENT = '1fc9b112-4b2a-4c7f-b5e0-22b66d30f1bf';
// const OAUTH_CLIENT = 'df8e748f-2c6c-48d2-8d9d-119fd61bf587';
const OAUTH_SECRET = '';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  redirectUrl = '';

  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError('Something bad happened; please try again later.');
  }

  private static log(message: string): any {
    // console.log(message);
  }

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    public jwtHelper: JwtHelperService,
    public baseService: BaseService
  ) {
  }

  login(loginData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();

    const body = new HttpParams()
      .set('username', loginData.name)
      .set('password', loginData.pass)
      .set('client_id', '1fc9b112-4b2a-4c7f-b5e0-22b66d30f1bf')
      .set('grant_type', 'password');

    return this.http.post<any>(AppConfig.tokenUrl, body, HTTP_OPTIONS).pipe(
      tap((res) => {
        this.tokenService.saveToken(res.access_token);
        this.tokenService.saveRefreshToken(res.refresh_token);
      }),
      catchError(AuthService.handleError)
    );
  }

  refreshToken(refreshData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set('refresh_token', refreshData.refresh_token)
      .set('grant_type', 'refresh_token')
      .set('client_id', refreshData.client_id);
    return this.http.post<any>(AppConfig.tokenUrl, body, HTTP_OPTIONS).pipe(
      tap((res) => {
        this.tokenService.saveToken(res.access_token);
        this.tokenService.saveRefreshToken(res.refresh_token);
      }),
      catchError(AuthService.handleError)
    );
  }

  logout(): void {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
  }

  register(data: any): Observable<any> {
    return this.http.post<any>(AppConfig.signupUrl, data).pipe(
      tap((_) => AuthService.log('register')),
      catchError(AuthService.handleError)
    );
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('access_token');
    if (token === null) {
      return false;
    }
    return !this.jwtHelper.isTokenExpired(token);
  }


  getTempToken(data): Observable<any> {
    return this.baseService.post(AppConfig.getTempPass, JSON.stringify(data), true);
  }

  resetPass(data): Observable<any> {
    return this.baseService.post(AppConfig.resetPass, JSON.stringify(data));
  }

  fetchUserData(): Observable<any> {
    return this.baseService.get<any>(AppConfig.userData, true);
  }

  storeLoginAttempt(data): Observable<any> {
    return this.baseService.post(AppConfig.storeLoginAttempt, JSON.stringify(data));
  }
}
