
export class GlobalConstants {

}

export class AppConfig
{


  public static CLIENT_ID = '1fc9b112-4b2a-4c7f-b5e0-22b66d30f1bf';
  public static baseUrl = 'https://api.apbackup.org/';
  // public static baseUrl = 'https://api.apbackup.org/';


  public static tokenUrl: string = AppConfig.baseUrl + 'oauth/token';
  public static signupUrl: string = AppConfig.baseUrl + 'oauth/signup';
  public static topMenuUrl: string =
    // '../../../../assets/json-data/top-menu-items.json';

    AppConfig.baseUrl + 'api/menu_items/all-media?_format=json';

  public static allMediaViewUrl: string =
    // '../../../../assets/json-data/all-media-view.json';
    AppConfig.baseUrl + 'all-media/rest/content?_format=json';

  public static allMediaSearchUrl: string =
    // '../../../../assets/json-data/all-media-view.json';
    AppConfig.baseUrl + 'rest/content?_format=json';

  public static textViewUrl: string =
    // '../../../../assets/json-data/text-view.json';
    AppConfig.baseUrl + 'rest/content?_format=json';

  public static videoViewUrl: string =
    // '../../../../assets/json-data/video-view.json';
    AppConfig.baseUrl + 'video?search&_format=json';

  public static photoViewUrl: string =
    // '../../../../assets/json-data/photo-view.json';

    AppConfig.baseUrl + 'rest/content?_format=json';

  public static audioViewUrl: string =
    // '../../../../assets/json-data/audio-view.json';
    AppConfig.baseUrl + '/api/solr/audio?keys';

  public static textDetailNodeUrl: string =
    // '../../../../assets/json-data/text-detail.json';
    AppConfig.baseUrl + 'node-detail?nid=';

  public static photoDetailNodeUrl: string =
    // '../../../../assets/json-data/photo-detail.json';
    AppConfig.baseUrl + 'node-detail?nid=';
    public static audioDetailNodeUrl: string =
    // '../../../../assets/json-data/photo-detail.json';
    AppConfig.baseUrl + 'node-detail?nid=';

  public static videoDetailNodeUrl: string =
    // '../../../../assets/json-data/video-detail.json';
    AppConfig.baseUrl + 'node-detail?nid=';

  public static hotTopicsUrl =
    '../../../../assets/json-data/hot-topics.json';
  // ApiUrls.baseUrl + '/node/';

  public static searchSuggestions: string =
    // '../../../../assets/json-data/search-suggestions.json';
    AppConfig.baseUrl +
    'rest/search/autocomplete?_format=json';

  public static downloadZipUrl: string =
  AppConfig.baseUrl + 'bulk/file/download?_format=json';

  public static banner: string = AppConfig.baseUrl + 'banner?_format=json';
  public static accountMenu = AppConfig.baseUrl + 'api/menu_items/account?_format=json';
  public static helpMenu = AppConfig.baseUrl + 'help?_format=json';
  public static siteStatus = AppConfig.baseUrl + 'status-page?_format=json';
  public static updateContent = AppConfig.baseUrl + 'content/update/check?_format=json';
  public static getTempPass = AppConfig.baseUrl + 'user/lost-password?_format=json';
  public static resetPass = AppConfig.baseUrl + 'user/lost-password-reset?_format=json';
  public static userData = AppConfig.baseUrl + 'rest/userdata?_format=json';
  public static storeLoginAttempt = AppConfig.baseUrl + 'rest/storeLoginAttempt?_format=json';
  public static storeDownloadAttempt = AppConfig.baseUrl + 'rest/storeDownloadAttempt?_format=json';
}
