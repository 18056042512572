import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfig } from '../constants/global-constants';
import { MenuItems } from '../models/top-menu-items.model';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {

  private activeLink$: BehaviorSubject<string> = new BehaviorSubject<string>('All Media');

  constructor(private http: HttpClient) {}

  getMenuItems(): Observable<MenuItems[]> {
    return this.http.get<MenuItems[]>(AppConfig.topMenuUrl);
  }

  setActiveLink(linkTitle): void{
    sessionStorage.setItem('currentActiveLink', linkTitle);
    this.activeLink$.next(linkTitle);
  }

  getActiveLink(): BehaviorSubject<string>{
    return this.activeLink$;
  }

}
