import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperService } from '../../services/helper.service';
import { NotificationService } from '../../services/notification.service';
@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent implements OnInit, AfterViewInit {

  FaqsList: any = [];
  showLoader: boolean = false;
  message = `This is a sample content?`;
  note = '';
  constructor(private helperService: HelperService,private notify: NotificationService,
    public dialogRef: MatDialogRef<HelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    // this.FaqsList = data;
    this.getHelpData();

  }

  ngOnInit(): void {
    // console.log(this.data);
    this.dialogRef.updateSize('62%', '80%');
  }

  getHelpData(): void {
    this.showLoader = true;
    this.helperService.getHelpMenu().subscribe(x => {
      this.FaqsList = x;
      this.showLoader = false;
    }, err => {
      this.notify.showError('Error while fetching FAQs list')
      this.showLoader = false;
    });
  }

  ngAfterViewInit(): void {

  }

  doAction(): void {
    this.dialogRef.close(true);
  }

}


