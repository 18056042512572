import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TagsComponent } from './layout/tags/tags.component';
import { HeadersComponent } from './layout/headers/headers.component';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import {AppConfigService} from './shared/services/app-config.service';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { BannerComponent } from './layout/banner/banner.component';
import { JwtHelperService, JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { FeaturesModule } from './features/features.module';
import { SiteStatusComponent } from './static/site-status/site-status.component';

const appInitializerFunction = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadConfig;
  };
};
@NgModule({
  declarations: [
    AppComponent,
    TagsComponent,
    HeadersComponent,
    DefaultLayoutComponent,
    BannerComponent,
    SiteStatusComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    JwtModule
  ],

  providers: [

    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFunction,
      multi: true,
      deps: [AppConfigService]
    }   ,
     { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService

  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
