import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { FlexModule } from '@angular/flex-layout';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';

import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';

const MATERIAL_MODULES = [
  MatToolbarModule, MatPaginatorModule,
  MatIconModule,
  MatSidenavModule,
  MatListModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatTabsModule,
  MatSelectModule,
  MatChipsModule,
  MatSlideToggleModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatStepperModule,
  MatRippleModule,
  MatMenuModule,
  // MatRadioGroup,
  MatRadioModule,
  DragDropModule,
  MatCardModule,
  MatExpansionModule,

  MatDialogModule,
  MatTableModule,
  MatSortModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatAutocompleteModule
];
const CORE_MODULES = [
  CommonModule,
  FormsModule,

  HttpClientModule,
];

const MODULES = [];

const LAYOUT_MODULES = [
  FlexModule,
  ...MATERIAL_MODULES
];


@NgModule({

  imports: [
    ...CORE_MODULES,
    ...MODULES,
    ...LAYOUT_MODULES
  ],

  exports: [
    ...CORE_MODULES,
    ...MODULES,
    ...LAYOUT_MODULES
  ],
  providers: [
    MatDatepickerModule,

  ]

})
export class MaterialModule { }
