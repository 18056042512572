<div class="header-sticky-top sticky-top">
  <nav class="navbar navbar-expand-lg navbar-light ">
    <!-- <div class="collapse navbar-collapse" id="navbarNav"></div>
  <div class="collapse navbar-collapse" id="navbarNav"></div> -->
    <!-- <div class="logo-div"> -->

    <div class="header-left">

      <div class="logo-div">
        <a (click)="routeToAllMedia()" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto">
          <img src="../../../assets/brand.svg" width="70" height="70" class="d-inline-block align-top"
            alt="brand-logo" />
        </a>
      </div>



    </div>

    <div class="header-mid">
      <app-search></app-search>
    </div>

    <div class="header-right">
      <button class="btn-profile d-lg-block" mat-icon-button aria-label="profile_button" (click)="openHelpDialog()">
        <img src="../../../assets/images/help-icon.svg" alt="">

      </button>
      <span>
        <button class="btn-profile" mat-icon-button [matMenuTriggerFor]="profileMenu" aria-label="profile_button">

          <span class="profile-user">
            <img src="../../../assets/images/profile.png" alt="">
          </span>

        </button>

        <mat-menu class="profileMenu-links" #profileMenu="matMenu" xPosition="before">
          <div appTheme>
            <div class="user-info-div">
              <div class="user-img">
                <img src="../../../assets/images/profile.png" alt="">
              </div>
              <div class="user-info">
                <span>{{getUserInfo()?.first_name }} {{getUserInfo()?.last_name}}</span>

                <span>{{getUserInfo()?.mail}}</span>

              </div>
            </div>
            <ul class="ul-profileMenu">
              <li class="menu1" *ngFor="let item of accountMenu" (click)="userMenuClick(item.title)">
                {{item.title}}
              </li>


            </ul>


          </div>
        </mat-menu>
      </span>
      <span class="header-menu">

        <button mat-button [matMenuTriggerFor]="menu" *ngIf="showMenu">
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button class='res-menu' mat-menu-item *ngFor="let item of menuItems"
            (click)="navigatetoUrl(item.title)">{{item?.title}}</button>
        </mat-menu>
      </span>
    </div>

    <!-- <br> -->



  </nav>
  <div class="header-links">
    <div class="header-ul">
      <ul class="navbar-nav" id="headlinks" *ngFor="let item of menuItems" (click)="activateClass(item)">
        <li class="nav-item " [ngClass]="selected === item.title?'nav-item active ' : 'nav-item ' ">
          <img [src]="'../../../../assets/images/' + makeUrl(item.title) + '-icon.svg'" alt="" />
          <a class="nav-link">
            {{ item.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</div>