import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { AppConfig } from '../constants/global-constants';
import {
  Filter,
  FilterSearchEvent,
  ToFilterData,
} from '../models/filter-search.model';
import { HotTopic } from '../models/hot-hopic.model';
import { Suggestions } from '../models/search-suggestions.model';
import { FascetsEntity } from '../models/video-view.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class SearchFilterService {
  private filterSearchChange = new Subject<FilterSearchEvent>();
  lastEvent: FilterSearchEvent = { filters: {}, searchKeyWord: '' };
   public searched = '';
  private filterSubject = new Subject<ToFilterData>();
  issearch = false;
  filterDataListner = this.filterSubject.asObservable();
  filterSearchListener = this.filterSearchChange.asObservable();

  constructor(private http: HttpClient, private baseService: BaseService) {}

  emitFilter(filter: Filter): void {
    this.lastEvent.filters = filter;
    this.filterSearchChange.next(this.lastEvent);
  }

  emitSearch(search: string): void {
    this.lastEvent.searchKeyWord = search;
    this.filterSearchChange.next(this.lastEvent);
  }
  emitToFilter(event: ToFilterData): void {
    if (event.categories && event.categories?.length > 0) {
      event.categories = (
        event.categories as FascetsEntity[][]
      )[0]![0]!.field_category?.map((category) => {
        return {
          value: Number(category.raw_value),
          name: category.values.value,
        };
      }) as { name: string; value: number }[];
    }
    this.filterSubject.next(event);
  }

  getSuggestions(keyword: string, type): Observable<Suggestions> {
    let url;
    if (type === 'all_media')
    {
     url = `${AppConfig.searchSuggestions}&page=1&sort_by=created&sort_order=desc&query=${keyword}`;
    }
    else{
     url = `${AppConfig.searchSuggestions}&page=1&sort_by=created&sort_order=desc&content_type=${type}&query=${keyword}`;
    }

  //  console.log(url);
    return this.http.get<Suggestions>(url);
  }

  getHotTopics(): Observable<HotTopic[]> {
    return this.http.get<HotTopic[]>(AppConfig.hotTopicsUrl);
  }
  getNodeDetails(nid: string, spinner): Observable<any> {

    return this.baseService
      .get<any>(AppConfig.photoDetailNodeUrl + nid + '?_format=json', spinner)
      .pipe(
        map((node: any): any => {
        return node;

        })

      );

  }
  getSearchCheck(): any
  {
    return this.issearch;
  }
  setSearchCheck(val): any
  {
    this.issearch = val;
  }
  setSearchKeyword(keyword): any
  {
    this.searched = keyword;
  }
  getSearchKeyWord(): any
  {
    return this.searched;
  }
}
