import { Component, HostListener, OnInit } from '@angular/core';
import { HeaderService } from '../../shared/services/header.service';
import { MenuItems } from '../../shared/models/top-menu-items.model';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '../../shared/services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { HelpDialogComponent } from '../../shared/components/help-dialog/help-dialog.component';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss']
})
export class HeadersComponent implements OnInit {
  selected: any;
  showMenu = false;
  show = false;
  menuItems?: MenuItems[];
  // accountMenu= [{title: "logout"},{title:"View Profile"}, {title:"Help"}];
  accountMenu: any;
  helpMenu: any;
  userInfo = { mail: 'someuser@ap.com', first_name: 'user', last_name: 'one' };
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.checkSize();
  }
  checkSize(): void {
    if (window.innerWidth <= 991) {
      this.showMenu = true;
    }
    else { this.showMenu = false; }
  }
  constructor(private headerService: HeaderService,
    public router: Router, private helperService: HelperService,
    private dialog: MatDialog, private cookieService: CookieService) {
    router.events.subscribe((val) => {

      if (val) {
        // location.pathname
        const currentRoute = location.pathname;
        if (currentRoute.includes('/features/all-media')) {
          this.selected = 'All Media';
        } else if (currentRoute.includes('/features/video')) {
          this.selected = 'Video';
        } else if (currentRoute.includes('/features/photo')) {
          this.selected = 'Photo';
        } else if (currentRoute.includes('/features/text')) {
          this.selected = 'Text';
        } else if (currentRoute.includes('/features/audio')) {
          this.selected = 'Audio';
        }
      }


    });
  }

  ngOnInit(): void {

    // const userInfoJson = this.cookieService.get('userInfo');
    // if (userInfoJson) {
    //   this.userInfo = JSON.parse(userInfoJson);
    // }
    this.checkSize();
    if (localStorage.getItem('menuItems') === null) {
      this.headerService.getMenuItems().subscribe((menuItems: MenuItems[]) => {
        this.menuItems = menuItems;
        localStorage.setItem('menuItems', JSON.stringify(this.menuItems));
      });
    }
    else {
      this.menuItems = JSON.parse(localStorage.getItem('menuItems'));
    }

    this.getMenu();
    //this.getHelpData();
  }

  getUserInfo():any{
    const userInfoJson = this.cookieService.get('userInfo');
    if (userInfoJson) {
      this.userInfo = JSON.parse(userInfoJson);
      return this.userInfo;
    }
    return null;
  }

  getHelpData(): void {
    this.helperService.getHelpMenu().subscribe(x => {
      this.helpMenu = x;
      //   console.log(this.helpMenu);
    });
  }
  
  openHelpDialog(): void {
    const dialogRef = this.dialog.open(HelpDialogComponent, {
      disableClose: true,
      width: '700px',
      height: '600px',
      data: []
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {

      }
    });
  }
  getMenu(): void {
    this.helperService.getAccountMenu().subscribe(x => {
      this.accountMenu = x;
      //   console.log(this.accountMenu);
    });
  }
  makeUrl(str: string): string {
    str = str.replace(/\s+/g, '-').toLowerCase();
    // console.log(str);
    return str;
  }
  navigatetoUrl(title): void {
    this.selected = title;
    // this.headerService.setActiveLink(title);
    const str = '/features/' + this.makeUrl(title);
    this.router.navigate([str]);
  }
  routeToAllMedia(): void {
    this.selected = 'All Media';
    this.router.navigate(['/features/all-media']);
  }
  activateClass(item): void {
    this.selected = item.title;

    this.navigatetoUrl(item.title);
  }
  userMenuClick(title): void {
    if (title === 'Log out') {
      localStorage.clear();
      this.cookieService.deleteAll('/');
      this.router.navigate(['/auth/login']);
    }
  }
}
