import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../constants/global-constants';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private http: HttpClient) { }
  getBanner(

  ): Observable<any> {
    const url: string =
      AppConfig.banner;

    return this.http.get<any>(url);
  }

  getAccountMenu(

  ): Observable<any> {
    const url: string =
      AppConfig.accountMenu;

    return this.http.get<any>(url);
  }

  getHelpMenu(

  ): Observable<any> {
    const url: string =
      AppConfig.helpMenu;

    return this.http.get<any>(url);
  }


  getSiteStatus(): Observable<any> {
    const url: string =  AppConfig.siteStatus;
    return this.http.get<any>(url);
  }
}
