import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const temp = value.split('+');
    const timezone = '(' + temp[0].slice(-3) + ')';
    const date = temp[0].slice(0, -3);

    return date + timezone;
  }

}
