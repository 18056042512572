<div class="search-component" >
  <div class="d-flex justify-content-center">
    <div class="search-input-div" (mouseout)="focusList = false">
      <div class="input-group ">
        <input
          class="form-control border-right-0 border"

          placeholder="Search"
          (search)="search($event)"
          id="example-search-input"
          [(formControl)]="searchField"
          (focus)="focusSearch = true"
          (blur)="focusSearch = false"
          (keydown.enter)="onSubmit()"
        />

        <span class="input-group-append">
          <button
          class="btn  border-left-0 border"
          type="button"
          >
            <mat-icon (click)="clear()">close</mat-icon>
          </button>
          <button
            class="btn btn-outline-secondary border-left-0 border"
            type="button"
            (click)="onSubmit()"
          >
            <!-- <i class="bi bi-search"></i> -->
          </button>
        </span>
      </div>
      <div class="dropdown" *ngIf="suggestions?.length>0">
        <ul
          *ngIf="(focusSearch || focusList) && suggestions"
          class="list-group dropdown"
          (mouseover)="focusList = true"
          (blur)="focusList = false"
        >
          <li class="list-group-item " *ngFor="let suggestion of suggestions">
            <a
              class="btn"
              (click)="clickSugg(suggestion)"
              searchHighlight
              [searchedWord]="searchField.value"
              [content]="suggestion.title[0]"
              [classToApply]="'font-weight-bold'"
              [setTitle]="true"
            >
             <p [innerHTML]="suggestion.title[0] |highlightSearch:searchField.value"> </p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>


