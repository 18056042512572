import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppConfig } from '../constants/global-constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { URL } from 'url';
@Injectable({
  providedIn: 'root'
})
export class BaseService {
  constructor(
    protected http: HttpClient,
    protected spinner: NgxSpinnerService
  ) {}
  get<T>(url: string, isSpinner = true): Observable<T> {
   // url = this.appConfig.getConfig('API_Base_Url') + url;

    if (isSpinner) {
      this.spinner.show();
    }

    return this.http.get<T>(url).pipe(
      tap(
        (response) => {
          if (isSpinner) {
            this.spinner.hide();
          }
        },
        (err) => {
          if (isSpinner) {
            this.spinner.hide();
          }
          return throwError(err);
        }
      )
    );
  }
  post(url: string, model: any, isSpinner = true): Observable<any> {

    if (isSpinner) {
      this.spinner.show();
    }


    return   this.http.post<any>(url, model).pipe(
      tap(
        (response) => {
          if (isSpinner) {
            this.spinner.hide();
          }
        },
        (err) => {
          if (isSpinner) {
            this.spinner.hide();
          }
          return throwError(err);
        }
      )
    );



  }
}
