import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../shared/services/helper.service';
import { CookieService } from 'ngx-cookie-service';
import { interval } from 'rxjs';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  bannerDetails: any;
  constructor(private helperService: HelperService, private cookieService: CookieService) { }
  ngOnInit(): void {

    this.getBanner();
    const intervalSub = interval(30000).subscribe(x => {
      this.getBanner();
    });

  }


  getBanner(): void {
    this.helperService.getBanner().subscribe(res => {
      this.bannerDetails = res;
    });
  }

}
