import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'AP-FRONTEND';
  constructor(private authService: AuthService) {

  }
  ngOnInit(): void {

    // this.authService.login({ username: 'arraza@ap.org', password: 'admin' }).subscribe(res=>{

    // })
  }
}
