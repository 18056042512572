import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  appConfig: any;
  constructor() { }
  loadConfig(): any {
    this.appConfig = environment;
    return environment;
  }
  getConfig(key: any): any {
    return this.appConfig[key];
  }
}
